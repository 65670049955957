<template>
  <v-dialog width="600" v-model="active">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text>
        <v-icon left>fal fa-user-plus</v-icon>
        Share
      </v-btn>
    </template>
    <v-card>
      <v-toolbar style="margin-bottom:1em">
        <v-toolbar-title>Share this list</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="active = false">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list elevation="2" style="height:250px;overflow:auto">
          <v-list-item v-for="({ photo, name, role, user: userRole }, index) in share" :key="'share-' + index">
            <v-list-item-avatar>
              <v-img v-if="photo" :src="photo"></v-img>
              <v-avatar v-else color="accent">{{ name.substr(0, 1) }}</v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>{{ name }}</v-list-item-title>
            <v-list-item-action style="min-width:115px">
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :disabled="role === 'owner' || (!isAdmin && userRole != directoryId)" text>
                    {{ role }}
                    <v-icon right>fal fa-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item :disabled="!isAdmin" @click="updateRole(index, 'admin')">
                    <v-list-item-title>Admin</v-list-item-title>
                    <v-list-item-avatar>
                      <v-icon v-if="role === 'admin'" color="success">far fa-check</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-list-item :disabled="_role === 'viewer'" @click="updateRole(index, 'editor')">
                    <v-list-item-title>Editor</v-list-item-title>
                    <v-list-item-avatar>
                      <v-icon v-if="role === 'editor'" color="success">far fa-check</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-list-item @click="updateRole(index, 'viewer')">
                    <v-list-item-title>Viewer</v-list-item-title>
                    <v-list-item-avatar>
                      <v-icon v-if="role === 'viewer'" color="success">far fa-check</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                  <template v-if="isOwner">
                    <v-divider></v-divider>
                    <v-list-item @click="updateRole(index, 'owner')">
                      <v-list-item-title>Make Owner</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-divider></v-divider>
                  <v-list-item @click="removeUser(userRole)">
                    <v-list-item-title>Remove</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-autocomplete v-if="isAdmin" v-model="selectedUser" :items="userSearchResults" :search-input.sync="userSearch" :cache-items="false" label="Search for Users to Add" class="mt-4" item-text="_id" item-value="_id" return-object no-filter outlined>
          <template v-slot:item="{ item }">
            <v-list-item-avatar>
              <v-img :src="item.photo"></v-img>
            </v-list-item-avatar>
            <v-list-item-title>{{ item.name.last + ', ' + item.name.first }}</v-list-item-title>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Done</v-btn>
        <v-spacer></v-spacer>
        <span>{{ message }}</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api'
export default {
  props: {
    id: String
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const directoryId = computed(() => user.value.directoryId)
    const active = ref(false)
    const share = ref([])
    const service = root.$feathers.service('directory/list')

    watch(() => props.id, () => {
      service.get(props.id).then((data) => load(data))
    })

    async function load ({ share: loadShare }) {
      share.value = []
      for (const row of loadShare) {
        if (!('user' in row)) continue
        const data = await root.$feathers.service('directory/people').get(row.user)
        const obj = { user: row.user, role: row.role, name: row.name }
        if (data != null && 'photo' in data) obj.photo = data.photo
        share.value.push(obj)
      }
    }

    const _role = computed(() => {
      const rec = share.value.filter(({ user }) => JSON.stringify(directoryId.value) === JSON.stringify(user))
      if (rec.length > 0) return rec[0].role
      return false
    })
    const isOwner = computed(() => _role.value === 'owner')
    const isAdmin = computed(() => _role.value === 'admin' || _role.value === 'owner')

    const changed = ref(false)

    const userSearch = ref('')
    const userSearchResults = ref([])
    watch(userSearch, (val) => {
      if (val == null || val === '' || typeof (val) === 'object') return
      // console.log(val)
      let query = {
        $sort: {
          'name.last': 1,
          'name.first': 1
        },
        $limit: 10
      }
      if (val.indexOf(',') >= 0) {
        const [last, first] = val.split(',')
        query['name.last'] = { $regex: last.trim(), $options: 'i' }
        query['name.first'] = { $regex: first.trim(), $options: 'i' }
      } else if (val.indexOf(' ') >= 0) {
        const [first, last] = val.split(' ')
        query['name.first'] = { $regex: first.trim(), $options: 'i' }
        query['name.last'] = { $regex: last.trim(), $options: 'i' }
      } else {
        query.$or = [
          { 'name.first': { $regex: val.trim(), $options: 'i' } },
          { 'name.last': { $regex: val.trim(), $options: 'i' } }
        ]
      }
      query._id = { $nin: [ ...share.value.map(({ user }) => user) ] }
      root.$feathers.service('directory/people').find({ query }).then(({ data }) => {
        userSearchResults.value = data
      })
    })

    const selectedUser = ref(null)
    watch(selectedUser, async (val) => {
      if (val != null && val !== '') {
        if (isAdmin.value) {
          const data = await service.patch(props.id, { $push: { share: { user: val._id, name: val.name.last + ', ' + val.name.first, role: 'viewer', photo: val.photo } } })
          addMessage('User added')
          load(data)
        }
        root.$nextTick(() => {
          userSearch.value = ''
          selectedUser.value = null
        })
      }
    })

    async function removeUser (user) {
      if (isAdmin.value || JSON.stringify(user) === JSON.stringify(directoryId.value)) {
        const data = await service.patch(props.id, { $pull: { share: { user } } })
        addMessage('Removal complete')
        load(data)
      }
    }

    async function updateRole (index, role) {
      if (role === 'owner') {
        if (!confirm('Are you sure you want to make ' + share.value[index].name + ' the owner of this list? Your ownership will be removed and you will now be an admin on this list.')) {
          return
        }
      }
      if (isAdmin.value || JSON.stringify(share.value[index].user) === JSON.stringify(directoryId.value)) {
        const patch = { ['share.' + index + '.role']: role }
        if (role === 'owner') {
          // Change any entries that were set to owner to only be an admin;
          // We should only ever have one owner, but we'll let this run for all just in case there is a glitch
          for (let i = 0; i < share.value.length; i++) {
            if (i === index) continue
            if (share.value[i].role === 'owner') {
              patch['share.' + i + '.role'] = 'admin'
            }
          }
        }
        const data = await service.patch(props.id, patch)
        addMessage('Role updated')
        load(data)
      }
    }

    const message = ref('')
    const timeout = ref(null)
    function addMessage (text, delay) {
      message.value = text
      if (timeout.value != null) clearTimeout(timeout)
      timeout.value = setTimeout(() => { message.value = '' }, delay || 5000)
    }

    return {
      user,
      directoryId,
      active,
      share,
      _role,
      isOwner,
      isAdmin,
      changed,
      userSearch,
      userSearchResults,
      selectedUser,
      removeUser,
      updateRole,
      message
    }
  }
}
</script>
