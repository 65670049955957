var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-user-plus")]),_vm._v(" Share ")],1)]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"margin-bottom":"1em"}},[_c('v-toolbar-title',[_vm._v("Share this list")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.active = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_c('v-card-text',[_c('v-list',{staticStyle:{"height":"250px","overflow":"auto"},attrs:{"elevation":"2"}},_vm._l((_vm.share),function(ref,index){
var photo = ref.photo;
var name = ref.name;
var role = ref.role;
var userRole = ref.user;
return _c('v-list-item',{key:'share-' + index},[_c('v-list-item-avatar',[(photo)?_c('v-img',{attrs:{"src":photo}}):_c('v-avatar',{attrs:{"color":"accent"}},[_vm._v(_vm._s(name.substr(0, 1)))])],1),_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-action',{staticStyle:{"min-width":"115px"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":role === 'owner' || (!_vm.isAdmin && userRole != _vm.directoryId),"text":""}},on),[_vm._v(" "+_vm._s(role)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"disabled":!_vm.isAdmin},on:{"click":function($event){return _vm.updateRole(index, 'admin')}}},[_c('v-list-item-title',[_vm._v("Admin")]),_c('v-list-item-avatar',[(role === 'admin')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("far fa-check")]):_vm._e()],1)],1),_c('v-list-item',{attrs:{"disabled":_vm._role === 'viewer'},on:{"click":function($event){return _vm.updateRole(index, 'editor')}}},[_c('v-list-item-title',[_vm._v("Editor")]),_c('v-list-item-avatar',[(role === 'editor')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("far fa-check")]):_vm._e()],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateRole(index, 'viewer')}}},[_c('v-list-item-title',[_vm._v("Viewer")]),_c('v-list-item-avatar',[(role === 'viewer')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("far fa-check")]):_vm._e()],1)],1),(_vm.isOwner)?[_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.updateRole(index, 'owner')}}},[_c('v-list-item-title',[_vm._v("Make Owner")])],1)]:_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.removeUser(userRole)}}},[_c('v-list-item-title',[_vm._v("Remove")])],1)],2)],1)],1)],1)}),1),(_vm.isAdmin)?_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.userSearchResults,"search-input":_vm.userSearch,"cache-items":false,"label":"Search for Users to Add","item-text":"_id","item-value":"_id","return-object":"","no-filter":"","outlined":""},on:{"update:searchInput":function($event){_vm.userSearch=$event},"update:search-input":function($event){_vm.userSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":item.photo}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item.name.last + ', ' + item.name.first))])]}}],null,false,1235976090),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}}):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.active = false}}},[_vm._v("Done")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.message))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }